import React from 'react'
export default function notFound() {
    return (
        <div className="not-found">
             <h1>Oops !</h1>
             <p>404 - Page introuvable !</p>
             <li>
                <a href="#" >Revenez à l'accueil</a>
             </li> 
            
        </div>
    )
}
